var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "archived-terms-container" }, [
    _c("section", { staticClass: "archived-terms" }, [
      _c(
        "div",
        {
          staticClass: "archived-toolbar mb-3 d-flex",
          class: {
            "align-items-end": _vm.selectedTerms && _vm.selectedTermsLength,
            "align-items-center":
              !_vm.selectedTerms || !_vm.selectedTermsLength,
          },
        },
        [
          _c("div", { staticClass: "d-flex flex-wrap" }, [
            _c(
              "div",
              { staticClass: "mr-1" },
              [
                _c(
                  "label",
                  { staticClass: "d-block" },
                  [
                    _vm._v(" Sort by "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          "open-delay": 500,
                          content:
                            "Sort by deletion date, from the most recent to oldest (newest) or oldest to most recent (oldest) order.",
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "sup",
                          [
                            _c("info-icon", {
                              staticClass:
                                "rb-icon-xs text-muted cursor-pointer",
                              attrs: { title: "" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    attrs: { size: "mini", disabled: _vm.isLoading },
                    model: {
                      value: _vm.selectedSort,
                      callback: function ($$v) {
                        _vm.selectedSort = $$v
                      },
                      expression: "selectedSort",
                    },
                  },
                  _vm._l(_vm.sortOptions, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item.value, label: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.selectedTerms && _vm.selectedTermsLength
            ? _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        "open-delay": 500,
                        content:
                          "Unarchiving terms will restore them to Searchbox Terms",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "info",
                                plain: "",
                                size: "small",
                                icon: "el-icon-refresh-left",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                              },
                              on: { click: _vm.onUnarchiveTerms },
                            },
                            [
                              _vm._v(
                                " Unarchive " +
                                  _vm._s(_vm.selectedTermsLength) +
                                  " " +
                                  _vm._s(
                                    _vm.selectedTermsLength > 1
                                      ? "terms"
                                      : "term"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c("archive-terms-help-button", {
                attrs: { title: "How does this table work?" },
              }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "archivedTable",
              staticClass: "rounded shadow-sm",
              attrs: {
                data: _vm.pagedTableData,
                "empty-text": _vm.emptyTableText,
                "default-sort": { prop: "date", order: "descending" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          { attrs: { data: props.row.listings } },
                          [
                            _c("el-table-column", {
                              attrs: { property: "listing", label: "Term" },
                            }),
                            _c("el-table-column", {
                              attrs: { property: "source", label: "Source" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Date", property: "time" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDate(scope.row.time * 1000)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "No. Terms" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.listings.length) + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.total > _vm.pageSize
            ? _c("el-pagination", {
                staticClass: "d-flex justify-content-end my-3",
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                },
                on: { "current-change": _vm.setPage },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }