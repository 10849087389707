var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container d-flex flex-column justify-content-center h-100",
    },
    [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-lg-8" }, [
          _vm._m(0),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-md-5" },
              [
                _c("h1", { staticClass: "h2 card-title" }, [
                  _vm._v(" 404 - Page Not Found "),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _vm._v(" Uh oh, the page you requested could not be found. "),
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _vm._v(" Where would you like to go? "),
                ]),
                _vm.isUserLoggedIn
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-column flex-md-row" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "el-button el-button--primary mb-2 mb-md-0",
                            attrs: { to: "/catalog-management" },
                          },
                          [_vm._v(" Go to Catalog ")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "el-button el-button--default ml-0 ml-md-2 mb-2 mb-md-0",
                            attrs: { to: "/dashboard" },
                          },
                          [_vm._v(" Go to Dashboard ")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "el-button el-button--default ml-0 ml-md-2 mb-2 mb-md-0",
                            attrs: { to: "/account" },
                          },
                          [_vm._v(" Go to Account ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isUserLoggedIn
                  ? _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { to: "/login" },
                      },
                      [_vm._v(" Log in ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row justify-content-center py-4" }, [
        _c(
          "footer",
          {
            staticClass:
              "col-12 col-lg-8 d-flex align-items-center justify-content-center",
          },
          [
            _c("div", [
              _vm._v(" Not what you were looking for? "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://helpcenter.getreviewbox.com/",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(" Help center "),
                  _c("external-link-icon", {
                    staticClass: "rb-icon-xs",
                    attrs: { title: "" },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img-container py-3 px-5 mb-3 text-center" },
      [
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            src: require("@/assets/images/powered_by_logo.webp"),
            alt: "Reviewbox Powered by Talkwalker",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }