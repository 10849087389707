var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-grid", {
        ref: _vm.gridRef,
        attrs: {
          service: _vm.service,
          "edit-header": true,
          "is-grid-loading": _vm.isGridLoading(_vm.service),
          "grid-state": _vm.gridState(_vm.service),
          "grid-filter": _vm.filterState(_vm.service),
          "download-options": _vm.downloadOptions(_vm.service),
          "enable-filter-actions": true,
          "enable-delete-rows": true,
          "enable-custom-column-edit": true,
          "suppress-row-click-selection": true,
          "column-cell-renderers": _vm.columnCellRenderers(_vm.service),
          "column-cell-editors": _vm.columnCellEditors(_vm.service),
        },
        on: {
          "set-grid-state": (payload) =>
            _vm.setGridState({ service: _vm.service, ...payload }),
          "select-download": _vm.onSelectDownload,
          "cell-value-changed": _vm.onCellValueChanged,
          "column-changed": (obj) => _vm.onColumnChanged("Searchterms", obj),
          "save-grid-filters": _vm.onSaveGridFilters,
          "delete-grid-filters": _vm.onDeleteGridFilters,
          "update-header-text": _vm.onUpdateHeaderText,
          "filter-changed": _vm.onFiltersChanged,
          "filter-cleared": _vm.onFiltersCleared,
        },
      }),
      _c("save-filters-modal", {
        attrs: {
          show: _vm.showSaveFiltersModal.show,
          "is-save-filter": _vm.showSaveFiltersModal.isSaveFilter,
          filters: _vm.showSaveFiltersModal.filters,
          "grid-filter": _vm.showSaveFiltersModal.gridFilter,
          "grid-sort": _vm.showSaveFiltersModal.gridSort,
          "is-loading": _vm.isGridFilterLoading,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.showSaveFiltersModal, "show", $event)
          },
          onConfirmSaveGridFilter: (obj) =>
            _vm.onConfirmSaveGridFilter("Searchterms", obj),
          onConfirmDeleteGridFilter: (obj) =>
            _vm.onConfirmDeleteGridFilter("Searchterms", obj),
          onCancelSaveFilters: _vm.onCancelSaveFilters,
        },
      }),
      _c("grid-download-modal", {
        attrs: {
          show: _vm.gridDownloadModal.show,
          export: _vm.gridDownloadModal.export,
        },
        on: {
          close: _vm.onResetGridDownloadModal,
          submit: _vm.onSubmitGridDownloadModal,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-secondary bg-gray-light search-terms-modal",
            visible: _vm.showSearchTerm,
            "before-close": _vm.onCloseSearchTerm,
            "destroy-on-close": true,
            width: "95%",
            top: "60px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSearchTerm = $event
            },
          },
        },
        [
          _vm.showSearchTerm
            ? _c(
                "div",
                {
                  staticClass: "el-dialog__title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", { staticClass: "text-capitalize" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.termPayload.term) +
                        ": Search Term Ranking "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showSearchTerm
            ? _c("term-visualizations", {
                attrs: { "is-modal": true, payload: _vm.termPayload },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }