var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card storage-card mb-2" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c(
              "base-input",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.continues",
                    value: "noTopicName|uniqueTopicName",
                    expression: "'noTopicName|uniqueTopicName'",
                    modifiers: { continues: true },
                  },
                ],
                staticClass: "mb-0",
                attrs: {
                  name: "name",
                  placeholder: "Reason Code",
                  label: _vm.type === "new" ? "reason-code" : "",
                  "input-id":
                    _vm.type === "new" ? "name" : `name--${_vm.inputUid}`,
                  error: _vm.errors.first("name"),
                  disabled: _vm.disabled,
                  required: "",
                },
                model: {
                  value: _vm.value.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name",
                },
              },
              [
                _vm.type === "new"
                  ? _c(
                      "label",
                      {
                        staticClass: "d-flex",
                        attrs: { slot: "label", for: "reason-code--name" },
                        slot: "label",
                      },
                      [
                        _vm._v(" Reason Codes "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "Reason codes are general topics that may be found within a product review.",
                              "open-delay": 500,
                              tabindex: -1,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "ml-1" },
                              [
                                _c("info-icon", {
                                  staticClass:
                                    "rb-icon-sm text-muted cursor-pointer",
                                  attrs: { title: "" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "base-input",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.continues",
                    value: "noTopicKeywords",
                    expression: "'noTopicKeywords'",
                    modifiers: { continues: true },
                  },
                ],
                ref: "keyword",
                staticClass: "mb-0",
                attrs: {
                  name: "keywords",
                  placeholder: "Enter keywords",
                  label: _vm.type === "new" ? "keywords" : "",
                  "input-id":
                    _vm.type === "new"
                      ? "keywords"
                      : `keywords--${_vm.inputUid}`,
                  value: _vm.valueList,
                  disabled: _vm.disabled,
                  error: _vm.errors.first("keywords"),
                  required: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.toList($event)
                  },
                },
              },
              [
                _vm.type === "new"
                  ? _c(
                      "label",
                      {
                        staticClass: "d-flex",
                        attrs: { slot: "label", for: "keywords--name" },
                        slot: "label",
                      },
                      [
                        _vm._v(" Keywords "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "Keywords are words that may be found in reviews about the reason code you provide.",
                              "open-delay": 500,
                              tabindex: -1,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "ml-1" },
                              [
                                _c("info-icon", {
                                  staticClass:
                                    "rb-icon-sm text-muted cursor-pointer",
                                  attrs: { title: "" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type === "new"
                  ? _c(
                      "small",
                      {
                        staticClass: "form-text text-muted",
                        attrs: { slot: "helperText" },
                        slot: "helperText",
                      },
                      [_vm._v(" Separate additional keywords with a comma ")]
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "d-flex align-items-center",
            staticStyle: { "min-width": "100px" },
          },
          [
            _vm.type == "saved" && _vm.disabled
              ? _c(
                  "el-tooltip",
                  {
                    attrs: { content: "Delete reason code", "open-delay": 500 },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-close",
                        size: "mini",
                        type: "danger",
                        circle: "",
                      },
                      on: { click: _vm.onDelete },
                    }),
                  ],
                  1
                )
              : _vm.type == "new"
              ? _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Save and add new reason codes",
                      "open-delay": 500,
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-plus",
                        size: "mini",
                        type: "primary",
                        circle: "",
                      },
                      on: { click: _vm.onSave },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.type == "saved" && _vm.disabled
              ? _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Edit reason codes or keywords",
                      "open-delay": 500,
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-s-tools",
                        size: "mini",
                        type: "info",
                        circle: "",
                      },
                      on: { click: _vm.onEditEnable },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.disabled && _vm.type === "saved"
              ? _c(
                  "el-tooltip",
                  { attrs: { content: "Cancel updates", "open-delay": 500 } },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-close",
                        size: "mini",
                        type: "danger",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCancelEdit()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.disabled && _vm.type === "saved"
              ? _c(
                  "el-tooltip",
                  { attrs: { content: "Save updates", "open-delay": 500 } },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-check",
                        size: "mini",
                        type: "primary",
                        circle: "",
                      },
                      on: { click: _vm.onUpdate },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }