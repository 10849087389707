var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row rb-reports-list-wrapper" }, [
    _c(
      "div",
      { staticClass: "col-12 mb-2" },
      [
        _c("collapse-header", {
          attrs: { loading: _vm.isLoading },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(" Reports and Exports "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Click to get a walkthrough of this page",
                        "open-delay": 500,
                      },
                    },
                    [
                      _vm.options.length
                        ? _c("sup", [
                            _c(
                              "button",
                              {
                                staticClass: "rb-help-btn btn btn-link btn-sm",
                                on: {
                                  click:
                                    _vm.onSelectReportsAndExportsWalkthrough,
                                },
                              },
                              [
                                _c("info-icon", {
                                  staticClass: "rb-icon",
                                  attrs: { title: "" },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "help",
              fn: function () {
                return [
                  !_vm.isLoading
                    ? _c("reports-and-exports-help-button")
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    !_vm.isLoading
      ? _c(
          "div",
          {
            staticClass:
              "col-12 d-flex flex-column flex-lg-row align-items-lg-end rb-select-report-filter-toolbar mb-3",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-column flex-lg-row" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column mr-2 mb-2 mb-lg-0 rb-report-search-input",
                  },
                  [
                    _c("label", { attrs: { for: "report-search-input" } }, [
                      _vm._v(" Search "),
                    ]),
                    _c(
                      "el-input",
                      {
                        attrs: {
                          id: "report-search-input",
                          placeholder: "Find a report or export",
                          name: "search",
                          size: "small",
                        },
                        on: { input: _vm.onSearchInput },
                        model: {
                          value: _vm.searchWord,
                          callback: function ($$v) {
                            _vm.searchWord = $$v
                          },
                          expression: "searchWord",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-center",
                            attrs: { slot: "prepend" },
                            slot: "prepend",
                          },
                          [
                            _c("search-icon", {
                              staticClass: "rb-icon-lg text-secondary",
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column rb-report-service-input mb-2 mb-lg-0",
                  },
                  [
                    _c("label", { attrs: { for: "report-services-filter" } }, [
                      _vm._v(" Service "),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "rb-select el-select--light",
                        attrs: {
                          id: "report-services-filter",
                          filterable: "",
                          name: "services",
                          placeholder: "Select service",
                          size: "small",
                        },
                        model: {
                          value: _vm.form.service,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "service", $$v)
                          },
                          expression: "form.service",
                        },
                      },
                      _vm._l(_vm.services, function (service) {
                        return _c("el-option", {
                          key: service.label,
                          staticClass: "text-capitalize",
                          attrs: { label: service.label, value: service.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.isFiltersActive
                  ? _c(
                      "el-button",
                      {
                        staticClass: "ml-2 align-self-end text-info",
                        attrs: { type: "text" },
                        on: { click: _vm.onClearFilters },
                      },
                      [_vm._v(" Clear filters ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-lg-auto" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "rb-report-type-input",
                    attrs: { size: "small" },
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: { "open-delay": 500, placement: "top" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("p", [
                                    _vm._v("A report is a "),
                                    _c("strong", [_vm._v("customizable")]),
                                    _vm._v(
                                      " summary of data, including graphs and metrics."
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2962081540
                        ),
                      },
                      [
                        _c("el-radio-button", {
                          staticClass: "text-capitalize",
                          attrs: { label: "reports" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      {
                        attrs: { "open-delay": 500, placement: "top" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      "An export contains the raw data behind the reports and is available for download as a "
                                    ),
                                    _c("strong", [_vm._v("CSV")]),
                                    _vm._v(" file."),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          362390816
                        ),
                      },
                      [
                        _c("el-radio-button", {
                          staticClass: "text-capitalize",
                          attrs: { label: "exports" },
                        }),
                      ],
                      1
                    ),
                    _c("el-radio-button", {
                      staticClass: "text-capitalize",
                      attrs: { label: "all" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      : _c("div", { staticClass: "col-12 d-flex mb-3" }, [
          _vm._m(0),
          _vm._m(1),
        ]),
    _vm.isReportLimitReached && _vm.form.type !== "exports"
      ? _c(
          "div",
          { staticClass: "col-12 my-3" },
          [
            _c(
              "alert-card",
              {
                attrs: {
                  title: "Report Limit Reached",
                  type: "info",
                  icon: "info-icon",
                },
              },
              [
                _c("div", [
                  _c("p", { staticClass: "mb-2" }, [
                    _vm._v(
                      " You have reached the limit of reports allowed. Contact support to increase the limit. "
                    ),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "el-button el-button--info",
                      attrs: {
                        href: "https://helpcenter.getreviewbox.com/kb-tickets/new",
                        target: "_blank",
                      },
                    },
                    [_vm._v(" Contact us ")]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "col-12 rb-reports-list" },
          [
            _vm._l(_vm.filteredOptions, function (option) {
              return _c("select-report-export-card", {
                key: option.type,
                staticClass: "mb-3",
                attrs: {
                  option: option,
                  disabled:
                    option.is_advanced_report && _vm.isReportLimitReached,
                },
              })
            }),
            _vm.filteredOptions.length === 0
              ? _c(
                  "div",
                  { staticClass: "text-center my-5" },
                  [
                    _c("file-search-outline-icon", {
                      staticClass: "rb-icon rb-icon-3x",
                    }),
                    _c("h5", [_vm._v(" No Results ")]),
                    _c("p", [
                      _vm._v(
                        " No reports or exports found. Please try updating your filters. "
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.onClearFilters },
                      },
                      [_vm._v(" Clear filters ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "skeleton skeleton-block mb-3 rounded" }),
          _c("div", { staticClass: "skeleton skeleton-block mb-3 rounded" }),
          _c("div", { staticClass: "skeleton skeleton-block mb-3 rounded" }),
          _c("div", { staticClass: "skeleton skeleton-block mb-3 rounded" }),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "skeleton skeleton-button mr-2" }),
      _c("div", { staticClass: "skeleton skeleton-button" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "skeleton skeleton-button" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }