var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      staticClass:
        "rb-select-report-card rb-card border border-light shadow-sm",
      class: { disable: _vm.disabled || _vm.isEnterpriseOnly },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-8" },
          [
            _c(
              "div",
              { staticClass: "card-content" },
              [
                _vm._t("title", function () {
                  return [
                    _c("div", { staticClass: "rb-card-title" }, [
                      _c("h5", [_vm._v(" " + _vm._s(_vm.option.name) + " ")]),
                    ]),
                  ]
                }),
                _vm._t("card-body", function () {
                  return [
                    _c("div", { staticClass: "rb-card-body w-75" }, [
                      _c("p", [_vm._v(" " + _vm._s(_vm.option.info) + " ")]),
                    ]),
                  ]
                }),
              ],
              2
            ),
            _vm._t("card-footer", function () {
              return [
                _c(
                  "div",
                  { staticClass: "rb-card-footer" },
                  _vm._l(_vm.tags, function (tag, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticClass: "mr-1 d-inline-flex align-items-center",
                        attrs: { type: tag.type, size: "medium" },
                      },
                      [
                        _c(tag.icon, {
                          tag: "component",
                          staticClass: "rb-icon rb-icon-xs mr-1",
                        }),
                        _vm._v(" " + _vm._s(tag.label) + " "),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "col-4 d-flex align-items-center justify-content-end",
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  placement: "top",
                  content:
                    "You have reached the limit of reports allowed. Contact support to increase the limit.",
                  disabled: _vm.isTooltipDisabled,
                  "open-delay": 500,
                },
              },
              [
                !_vm.disabled && _vm.isEnterpriseOnly
                  ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(
                          " The " +
                            _vm._s(_vm.option.name || _vm.option.type) +
                            " " +
                            _vm._s(
                              _vm.option.is_advanced_report
                                ? "report"
                                : "export"
                            ) +
                            " is unavailable for your account. "
                        ),
                      ]),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(" Please contact Sales to "),
                        _c(
                          "a",
                          {
                            staticClass: "text-underline",
                            attrs: {
                              href: "https://www.getreviewbox.com/contact-reviewbox",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(" upgrade your account "),
                            _c("external-link-icon", {
                              staticClass: "rb-icon-xs",
                            }),
                          ],
                          1
                        ),
                        _vm._v(
                          " and gain access to this " +
                            _vm._s(
                              _vm.option.is_advanced_report
                                ? "report"
                                : "export"
                            ) +
                            ". "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "rb-report-select-button",
                        attrs: {
                          type: "info",
                          plain: "",
                          disabled: _vm.disabled || _vm.isEnterpriseOnly,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onSelectOption(_vm.option)
                          },
                        },
                      },
                      [
                        _vm._v(" Select "),
                        _c("i", {
                          staticClass: "el-icon-right font-weight-bold",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }