var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "tabs-navbar",
        { attrs: { tabs: _vm.tabs, service: _vm.service } },
        [
          _vm.showGridHelp
            ? _c("grid-help-button", { staticClass: "ml-auto" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "slide-x-right-transition",
        { attrs: { duration: 300, mode: "out-in" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }