var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 d-xl-none" },
      [_c("tabs-navbar", { attrs: { tabs: _vm.tabs, service: _vm.service } })],
      1
    ),
    _c(
      "div",
      { staticClass: "col-3 col-xl-2 d-none d-xl-block" },
      [
        _c("accounts-navbar", {
          attrs: { tabs: _vm.tabs, service: _vm.service },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "col-12 col-xl-10" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }