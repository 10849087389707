var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-12 col-xl-10",
        staticStyle: { "max-width": "1200px" },
      },
      [
        _c("topic-card", {
          ref: "create",
          attrs: { type: "new" },
          on: {
            save: function ($event) {
              return _vm.onAddNew()
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "col-12 col-xl-10",
        staticStyle: { "max-width": "1200px" },
      },
      [
        _vm.isLoading
          ? [_vm._m(0)]
          : _vm._l(_vm.investigations, function (investigation, index) {
              return _c("topic-card", {
                key: index,
                attrs: { "data-vv-scope": "saved" + index, name: index },
                on: {
                  delete: function ($event) {
                    return _vm.onDelete(index)
                  },
                  update: function ($event) {
                    return _vm.onUpdate(index)
                  },
                },
                model: {
                  value: _vm.investigations[index],
                  callback: function ($$v) {
                    _vm.$set(_vm.investigations, index, $$v)
                  },
                  expression: "investigations[index]",
                },
              })
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "skeleton skeleton-input" }),
          ]),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "skeleton skeleton-input" }),
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center",
              staticStyle: { "min-width": "100px" },
            },
            [
              _c("div", { staticClass: "skeleton skeleton-icon icon-xl" }),
              _c("div", { staticClass: "skeleton skeleton-icon icon-xl ml-2" }),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }