import { render, staticRenderFns } from "./CatalogManagementLayout.vue?vue&type=template&id=cd758fc2&"
import script from "./CatalogManagementLayout.vue?vue&type=script&lang=js&"
export * from "./CatalogManagementLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cd758fc2')) {
      api.createRecord('cd758fc2', component.options)
    } else {
      api.reload('cd758fc2', component.options)
    }
    module.hot.accept("./CatalogManagementLayout.vue?vue&type=template&id=cd758fc2&", function () {
      api.rerender('cd758fc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/CatalogManagement/CatalogManagementLayout.vue"
export default component.exports