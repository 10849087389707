var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c(
      "div",
      { staticClass: "col-12 col-md-10" },
      [
        _c(
          "simple-wizard",
          {
            attrs: {
              loading: _vm.isLoading,
              "finish-button-text": "Upload",
              "hide-navigation": true,
            },
            on: {
              "cancel-wizard": () => _vm.$router.push("/catalog-management"),
              "wizard-complete": _vm.wizardComplete,
            },
          },
          [
            _c("template", { slot: "header" }, [
              _c("h4", { staticClass: "card-title mb-0" }, [
                _vm._v(" Upload Reference Content "),
              ]),
            ]),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": () =>
                    _vm.validateStep("reference-content-upload"),
                },
              },
              [
                _c("reference-content-upload", {
                  ref: "reference-content-upload",
                  on: { "on-validated": _vm.onStepValidated },
                }),
                _vm.uploadError
                  ? _c("el-alert", {
                      staticClass: "mt-3",
                      attrs: {
                        type: "error",
                        title: "Unable to Upload",
                        description: _vm.uploadError,
                        closable: false,
                        "show-icon": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          2
        ),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mt-3" }, [
      _vm._v(" Need more help? "),
      _c(
        "a",
        {
          attrs: {
            href: "https://helpcenter.getreviewbox.com/getting-started-with-copybox#copybox_reference_content",
            target: "_blank",
          },
        },
        [_vm._v(" Read about managing Copybox reference content. ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }