var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-fields-container" }, [
    _c("section", { staticClass: "custom-fields" }, [
      _c(
        "div",
        {
          staticClass: "archived-toolbar mb-3 d-flex",
          class: {
            "align-items-end": _vm.selectedFields && _vm.selectedFieldsLength,
            "align-items-center":
              !_vm.selectedFields || !_vm.selectedFieldsLength,
          },
        },
        [
          _c("div", { staticClass: "d-none d-lg-flex flex-wrap" }, [
            _c(
              "div",
              { staticClass: "mr-1" },
              [
                _c("label", { staticClass: "d-block" }, [
                  _vm._v(" Filter by "),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { size: "mini", disabled: _vm.isLoading },
                    on: { change: _vm.onServiceChanged },
                    model: {
                      value: _vm.selectedService,
                      callback: function ($$v) {
                        _vm.selectedService = $$v
                      },
                      expression: "selectedService",
                    },
                  },
                  _vm._l(_vm.serviceOptions, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item, label: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.selectedFields && _vm.selectedFieldsLength
            ? _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        plain: "",
                        size: "small",
                        icon: "el-icon-delete-solid",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.onDeleteCustomFields },
                    },
                    [
                      _vm._v(
                        " Delete " +
                          _vm._s(_vm.selectedFieldsLength) +
                          " " +
                          _vm._s(
                            _vm.selectedFieldsLength > 1 ? "fields" : "field"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c("custom-fields-help-button", {
                attrs: { title: "How does this table work?" },
              }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "fieldsTable",
              staticClass: "rounded shadow-sm",
              attrs: {
                data: _vm.pagedTableData,
                "empty-text": _vm.emptyTableText,
                "default-sort": { prop: "date", order: "descending" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { label: "Custom Field", property: "field" },
              }),
            ],
            1
          ),
          _vm.total > _vm.pageSize
            ? _c("el-pagination", {
                staticClass: "d-flex justify-content-end my-3",
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                },
                on: { "current-change": _vm.setPage },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }